<template>
	<settingsPagesLayout>
		<div class="p-inside-manage">
			<div class="page_title">Payments</div>
			<section class="wrap_s_block stripe">
				<div class="title_section">
					<h2 class="name_section">Stripe</h2>
				</div>
				<div class="body_ stripe_account_info">
					<stripeStatus></stripeStatus>
				</div>
			</section>
			<section class="wrap_s_block earnings">
				<div class="title_section">
					<h2 class="name_section">Earnings</h2>
				</div>
			</section>
		</div>
	</settingsPagesLayout>
</template>

<script>
import settingsPagesLayout from "@/layouts/settingsPagesLayout";
import stripeStatus from "@/components/stripeStatus.vue";

export default {
	name: "payments",
	components: {
		settingsPagesLayout,
		stripeStatus
	},
	data() {
		return {
		}
	},
	computed: {

	},
	mounted() {

	},
	methods: {
	}
}
</script>

<style lang="scss">

</style>