<template>
    <div class="wrap_stripe_status">

        <template v-if="loaderGetStripeAccount">
            <v-skeleton-loader type="image" class="skeleton_stripe_status" height="56"></v-skeleton-loader>
            <v-skeleton-loader type="button" height="44" max-width="230" class="skeleton_stripe_btn"></v-skeleton-loader>
        </template>


        <!-- None Stripe Account -->
        <template v-if="stripeStatus === 'no-account' && !loaderGetStripeAccount">
            <div class="stripe_status no-account">
                <span>You do not have an account.</span>
                <button type="button" @click="signUpStripe">Click here</button>
                <span>to begin receiving money</span>
            </div>
            <button type="button" class="stripe_sign_up c2a_btn medium" v-ripple @click="signUpStripe"
                    :class="{loading: loaderCreateStripeAccount}">
                <v-progress-circular :width="2" indeterminate></v-progress-circular>
                Sign up
                <span class="arrow">
                    <span class="line_svg"></span>
                    <svg width="7" height="10" viewBox="0 0 7 10" fill="none">
                        <path d="M1.5 1.5L5 5L1.5 8.5" stroke="white" stroke-width="2" stroke-linecap="square"/>
                    </svg>
                </span>
            </button>
        </template>

        <!-- Restricted Stripe Account -->
        <template v-if="stripeStatus === 'restricted'">
            <div class="stripe_status restricted">
                <span>There is a problem with your Stripe account.</span>
                <button type="button" @click="resolveIssue">Click here</button>
                <span>to resolve the issue</span>
            </div>
            <button type="button" class="stripe_resolve_issue" v-ripple @click="resolveIssue"
                    :class="{loading: loaderGetStripeAccountLink}">
                Resolve issue
                <v-progress-circular :width="2" indeterminate></v-progress-circular>
            </button>
        </template>


        <!-- Complete Stripe Account -->
        <template v-if="stripeStatus === 'complete'">
            <div class="stripe_status complete">
                You are signed up and everything is working perfectly
            </div>
            <button type="button" class="visit_stripe primary_btn medium" v-ripple @click="visitStripe"
                    :class="{loading: loaderGetStripeAccountLink}">
                Visit Stripe
                <v-progress-circular :width="2" indeterminate></v-progress-circular>
            </button>
        </template>


        <!-- Pending Stripe Account -->
        <template v-if="stripeStatus === 'pending'">
            <div class="stripe_status no-account">
                <span>Your registration with Stripe is being reviewed. We will notify you when theres an update.</span>
                <button type="button" @click="visitStripe" :class="{loading: loaderGetStripeAccountLink}">
                    Notification preferences
                    <v-progress-circular :width="2" indeterminate></v-progress-circular>
                </button>
            </div>
            <button type="button" class="visit_stripe primary_btn medium" v-ripple @click="visitStripe">Visit Stripe</button>
        </template>
    </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
    name: "stripeStatus",
    data() {
        return {
            stripeStatus: null,
        }
    },
    computed: {
        ...mapGetters({
            createLinkStripeAccount: 'CREATE_LINK_STRIPE_ACCOUNT',
            stripeAccount: 'STRIPE_ACCOUNT',
            stipeAccountLink: 'STRIPE_ACCOUNT_LINK',

	        loaderCreateStripeAccount: 'LOADER_CREATE_STRIPE_ACCOUNT',
	        loaderGetStripeAccount: 'LOADER_GET_STRIPE_ACCOUNT',
	        loaderGetStripeAccountLink: 'LOADER_GET_STRIPE_ACCOUNT_LINK',
        })
    },
    created() {
        this.getStripeAccountData();
    },
    methods: {
        ...mapActions({
            createStripeAccount: 'CREATE_STRIPE_ACCOUNT',
            getStripeAccount: 'GET_STRIPE_ACCOUNT',
            getLinkStripeAccount: 'GET_LINK_STRIPE_ACCOUNT'
        }),
        getStripeAccountData() {
            this.getStripeAccount()
                .then(() => {
                    this.stripeStatus = this.stripeAccount.status;
                    // if (this.stripeStatus !== 'no-account') {
					// 	// * :TODO *
                    // }
                })
                .catch(err => {
                    console.error(`getStripeAccount, ${err}`);
                })
        },
        signUpStripe() {
			let params = {
				callback_success_url: `${window.location.protocol}//${window.location.host}/settings/payments`,
			}
            this.createStripeAccount(params)
                .then(() => {
                    // window.open(this.createLinkStripeAccount, '_blank');
	                window.location.href = this.createLinkStripeAccount;
                })
                .catch(err => {
                    console.error(`signUpStripe, ${err}`);
                })
	            .finally(() => {
		            this.getStripeAccountData();
	            })
        },
        visitStripe() {
            this.getLinkStripeAccount()
                .then(() => {
                    // window.open(this.stipeAccountLink, '_blank');
	                window.location.href = this.stipeAccountLink;
                })
                .catch(err => {
                    console.error(`getLinkStripeAccount, ${err}`);
                })
        },
        resolveIssue() {
	        let params = {
		        callback_success_url: `${window.location.protocol}//${window.location.host}/settings/payments`,
	        }
            this.getLinkStripeAccount(params)
                .then(() => {
                    // window.open(this.stipeAccountLink, '_blank','width=1000,height=1000,top=50,left=50');
	                window.location.href = this.stipeAccountLink;
                })
                .catch(err => {
                    console.error(`getLinkStripeAccount, ${err}`);
                })
        }
    }
}
</script>

<style scoped>

</style>